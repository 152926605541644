body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Inter Variable",
    "Open Sans Variable", "Montserrat Variable", "Dancing Script Variable",
    "Caveat Variable", "Merienda Variable",
    "Big Shoulders Inline Display Variable", "Edu NSW ACT Foundation Variable",
    "Darker Grotesque Variable", "Red Hat Display Variable",
    "Roboto Mono Variable", "Comfortaa Variable",  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.DraftEditor-root {
  font-size: 14px !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.ScrollbarsCustom-Thumb {
  background: rgb(13, 13, 13) !important; 
}
